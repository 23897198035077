import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Columbia({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={{
        backgroundColor: styles.forElement("background").backgroundColor
      }}
    >
      <div
        className="formkit-background"
        name="background"
        style={styles.forElement("background")}
      />
      <div className="formkit-container">
        <Elements.Image className="formkit-logo" name="logo" />
        <Elements.Heading
          className="formkit-header"
          name="header"
          defaults={{ content: "Name of your ebook" }}
        />
        <div className="formkit-card" style={styles.forElement("card")}>
          <Elements.Image
            className="formkit-image-column"
            name="image"
            size={{ h: 550, w: 400 }}
          />
          <Elements.Form style={styles.forElement("form")}>
            <Elements.Content
              name="subheader"
              defaults={{
                content: "Download the guide"
              }}
            />
            <Elements.Errors />
            <Elements.CustomFields>
              <Elements.Button
                name="submit"
                group="button"
                defaults={{ content: "Send it my way!" }}
              />
            </Elements.CustomFields>

            <Elements.AddFieldButton />
            <Elements.Content
              name="disclaimer"
              defaults={{
                content: "We respect your privacy. Unsubscribe at any time."
              }}
            />
          </Elements.Form>
        </div>
        <Elements.Region className="formkit-content" name="content">
          <Elements.Content
            defaults={{
              content:
                "Add some details on what the ebook is about and what it includes"
            }}
          />
        </Elements.Region>

        <Elements.BuiltWith background="background" image="background" />
      </div>
    </Elements.LandingPage>
  )
}

Columbia.style = "landing_page"
Columbia.categories = ["eBook", "Podcast"]
Columbia.thumbnail = ""
Columbia.preview = "https://demo.ck.page/columbia"
Columbia.fields = [
  {
    name: "first_name",
    label: "First Name"
  },
  {
    name: "email_address",
    label: "Email Address"
  }
]

export default createTemplate(Columbia, { name: "Columbia" })
